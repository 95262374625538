import React from "react"
import RecipeItem from "../RecipeItem"

import { recipesGrid, breakpoints } from "../../styles"
import useWindowSize from "../../hooks/useWindowSize"

import { Recipe } from "../../types/Recipe"

type Props = {
  recipes: Recipe[]
}

const RecipesGrid: React.FC<Props> = ({ recipes }) => {
  const { width } = useWindowSize()

  return (
    <div css={recipesGrid}>
      {recipes.map((recipe, idx) => (
        // replace order with counter
        <RecipeItem key={recipe.id} {...recipe} order={idx} />
      ))}
      {width && width <= breakpoints.md && <div className="mobile-spacer"></div>}
    </div>
  )
}

export default RecipesGrid
