import { headingsFont } from "../../styles";
import styled from "@emotion/styled";

type Props = {
  alignRight?: boolean
}

export default styled.p<Props>`
  ${headingsFont}
  font-size: 1.75rem;
  text-align: ${props => props.alignRight && `right`};
`