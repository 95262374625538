import React from "react"
import PointerArrow from "../../svgs/PointerArrow"
import { css } from '@emotion/react'
import styled from "@emotion/styled"

const pointerStyles = css`
  z-index: 999;
  position: relative;
  display: flex;
  color: var(--secondary-light);
  width: auto;

  .pointer-arrow {
    position: relative;
  }
`

const Pointer: React.FC = ({ children, ...props }) => {
  return (
    <div css={pointerStyles}{...props}>
      <PointerArrow className="pointer-arrow" />
      <span>{children}</span>
    </div>
  )
}

export const TopPointer = styled(Pointer)`
  top: 100px;
  left: -180px;
  align-items: flex-start;

  .pointer-arrow {
    margin-top: 0.75rem;
    margin-left: 0.5rem;
    order: 9999;
  }
`

export const BottomPointer = styled(Pointer)`
  top: -25px;
  left: 150px;
  align-items: flex-end;

  .pointer-arrow {
    margin-bottom: 0.75rem;
    margin-right: 0.5rem;
    transform: rotate(180deg);
  }
`
