import React from "react"
import styled from "@emotion/styled"

const Div = styled.div`
  margin-bottom: 2rem;

  p {
    margin-bottom: 0;
  }

  a {
    color: unset;
  }
`

const H6 = styled.h6`
  text-transform: uppercase;
  letter-spacing: 3px;
`

type Props = {
  title: string
}

const ContactInfo: React.FC<Props> = ({ title, children }) => {
  return (
    <Div>
      <H6>{title}</H6>
      {children}
    </Div>
  )
}

export default ContactInfo
