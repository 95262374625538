import React from "react"
import { Formik, Form, useField, FormikHelpers, FormikProps } from "formik"
import styled from "@emotion/styled"
import { headingsFont, mediaQueries } from "../../styles"
import Button from "../Button"
import axios from "axios"
import { useTranslation } from "gatsby-plugin-react-i18next"

interface FormFieldProps {
  label: string
  id: string
  type: string
  name: string
  placeholder: string
}

const Label = styled.label`
  ${headingsFont}
  display: block;
  margin-bottom: 1rem;
  text-transform: uppercase;
`

const Input = styled.input`
  display: block;
  width: 100%;
  background-color: transparent;
  padding: 0;
  padding-bottom: 0.75rem;
  border: none;
  border-bottom: 1px solid var(--primary);
`

const Textarea = Input.withComponent("textarea")

const FormGroup = styled.div`
  margin-bottom: 4rem;
`

const SubmitButton = styled(Button)`
  ${mediaQueries.max576} {
    margin-left: auto;
    margin-right: auto;
  }
`

const FormStatusMessage = styled.p<{ status: string }>`
  margin-top: 1rem;
  color: ${({ status }) => (status === "success" ? "var(--secondary)" : "red")};
`

export const FormField: React.FC<FormFieldProps> = ({ label, type, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <FormGroup>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      {type === "textarea" ? (
        <Textarea {...field} {...props} />
      ) : (
        <Input type={type} {...field} {...props} />
      )}
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </FormGroup>
  )
}

interface FormValues {
  name: string
  email: string
  message: string
}

interface Props {
  children: React.ReactNode
  initialValues: FormValues
}

function BaseForm({ initialValues, children }: Props) {
  const { t } = useTranslation("", { keyPrefix: "contactSection.contactForm" })

  async function handleSubmit(
    values: FormValues,
    { setStatus, resetForm }: FormikHelpers<FormValues>,
  ): Promise<void> {
    try {
      await axios.post("/", encode({ "form-name": "Contact Form", ...values }), {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      resetForm()
      setStatus("success")
    } catch (error) {
      console.error(error)
      setStatus("error")
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(props: FormikProps<FormValues>) => (
        <Form data-netlify="true" method="post" name="Contact Form">
          {children}

          <SubmitButton variant="secondary-outline" type="submit">
            {t("submitButton")}
          </SubmitButton>

          {props.status ? (
            <FormStatusMessage status={props.status}>
              {t(props.status === "success" ? "successMessage" : "errorMessage")}
            </FormStatusMessage>
          ) : null}
        </Form>
      )}
    </Formik>
  )
}

function encode(data: Record<string, string | number | boolean>) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default BaseForm
