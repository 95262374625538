import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { mediaQueries } from "../../styles/"

import Section from "../Section"
import SectionTitle from "../SectionTitle"

const hideSectionTitleMobile = css`
  ${SectionTitle} {
    display: none;

    ${mediaQueries.min992} {
      display: block;
    }
  }
`

export const ProductsSection = styled(Section)`
  ${hideSectionTitleMobile}

  h3 {
    text-align: center;
    text-transform: uppercase;

    ${mediaQueries.max576} {
      font-size: 1.125rem;
    }

    ${mediaQueries.min992} {
      max-width: 44ch;
      margin: 0 auto;
      margin-bottom: 5rem;
    }
  }

  .products-carousel {
    margin-bottom: 3.5rem;

    ${mediaQueries.min992} {
      margin-bottom: 6rem;
    }
  }
`

export const RecipesSection = styled(Section)`
  ${hideSectionTitleMobile}

  scroll-margin-top: 7.5rem;
`

export const TraditionSection = styled(Section)`
  ${hideSectionTitleMobile}

  padding-bottom: 0;

  .background-image {
    display: none;
  }

  ${mediaQueries.min992} {
    position: relative;
    padding-bottom: 21vw; /* 1920px -> 100vw; 400px ~ 21vw */

    .background-image {
      display: block;
      z-index: -9999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .background-image-mobile {
      display: none;
    }
  }
`

export const LaYucaSection = styled(Section)`
  scroll-margin-top: 106px;

  ${mediaQueries.max1760} {
    h2 {
      font-size: 2.5rem;
    }
  }

  ${mediaQueries.max576} {
    padding-bottom: 2rem;
    scroll-margin-top: 90px;

    ${SectionTitle} {
      margin-bottom: 2rem;
    }

    h2 {
      max-width: 16ch;
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
  }
`

export const BenefitsSection = styled(Section)`
  ${hideSectionTitleMobile}

  padding-top: 1rem;
  padding-bottom: 0;
`

export const LaPlantaSection = styled(Section)`
  padding-top: 0;

  ${mediaQueries.max576} {
    padding-bottom: 0;
  }
`
