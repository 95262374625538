import styled from "@emotion/styled";

export default styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .gatsby-image-wrapper {
    align-self: stretch;
  }
`
