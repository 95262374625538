import { css } from "@emotion/react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Section from "../Section"
import SectionTitle from "../SectionTitle"
import ContactInfo from "./ContactInfo"
import BaseForm, { FormField } from "../Form"
import SocialBar from "../SocialBar"

import { mediaQueries } from "../../styles"
import styled from "@emotion/styled"
import { useTranslation } from "gatsby-plugin-react-i18next"

const ContactSection = () => {
  const { t } = useTranslation("", { keyPrefix: "contactSection" })

  return (
    <StyledSection className="contact-us-section" id={t("anchor")}>
      <Container>
        <Row>
          <Col
            sm={3}
            css={css`
              ${mediaQueries.max576} {
                margin-bottom: 7rem;
              }
            `}
          >
            <SectionTitle>{t("title")}</SectionTitle>

            <ContactInfo title={t("address.title")}>
              <p>Calle Margarita #5,</p>
              <p>Nave #1, Res. San Benito,</p>
              <p>{t("address.country")}</p>
            </ContactInfo>

            <ContactInfo title="Email">
              <p>
                <a href="mailto:sharo.casabi@casabi.do">sharo.casabi@casabi.do</a>
              </p>
              <p>
                <a href="mailto:csanchez@casabi.do">csanchez@casabi.do</a>
              </p>
            </ContactInfo>

            <ContactInfo title={t("phoneNumbers.title")}>
              <p>
                {t("phoneNumbers.officePhoneLabel")}.{" "}
                <a href="tel:8097013448">809-701-3448</a>
              </p>
              <p>
                WhatsApp. <a href="tel:8098756775">809-875-6775</a>
              </p>
            </ContactInfo>

            <ContactInfo title={t("socialMedia.title")}>
              <SocialBar
                css={css`
                  --social-icon-fill: none;
                  --social-icon-stroke: var(--secondary);
                `}
              />
            </ContactInfo>
          </Col>

          <Col
            sm={{
              span: 6,
              offset: 2,
            }}
          >
            <h2>{t("contactForm.title")}</h2>

            <ContactForm
              initialValues={{
                name: "",
                email: "",
                message: "",
              }}
            >
              <FormField
                label={t("contactForm.nameInput.label")}
                id="name"
                name="name"
                type="text"
                placeholder={t("contactForm.nameInput.placeholder")}
              />
              <FormField
                label={t("contactForm.emailInput.label")}
                id="email"
                name="email"
                type="email"
                placeholder={t("contactForm.emailInput.placeholder")}
              />
              <FormField
                label={t("contactForm.messageInput.label")}
                id="message"
                name="message"
                type="textarea"
                placeholder={t("contactForm.messageInput.placeholder")}
              />
            </ContactForm>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  )
}

export default ContactSection

const StyledSection = styled(Section)`
  scroll-margin-top: 7.5rem;
`

const ContactForm = styled(BaseForm)``
