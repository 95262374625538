import React from "react"
import styled from "@emotion/styled"
import { breakpoints, headingsFont, mediaQueries } from "../../styles"
import Triangle from "../../svgs/Triangle"
import { GatsbyImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { Recipe } from "../../types/Recipe"
import useWindowSize from "../../hooks/useWindowSize"

type Props = Pick<
  Recipe,
  "mainPhoto" | "title" | "tagline" | "description" | "slug"
> & {
  order: number
}

const RecipeItem: React.FC<Props> = ({
  slug,
  mainPhoto,
  title,
  tagline,
  description: varDesc,
  order,
}) => {
  const description = Boolean(varDesc) ? varDesc : ""
  const { width } = useWindowSize()
  const isMobile = width && width < breakpoints.md
  const maxDescriptionLength = isMobile ? 80 : 120
  const slicedDescription = description.split(" ").slice(0, 12).join(" ")

  return (
    <Item onClick={() => navigate(`/receta/${slug}`)}>
      <AspectRatioWrapper>
        <Image image={mainPhoto.gatsbyImageData} alt={title} />
        <RecipeContent>
          <StyledTriangle />
          <h6>{title}</h6>
          <h5>{tagline}</h5>
          <p>
            {slicedDescription}
            {description.length > maxDescriptionLength ? "…" : ""}
          </p>
          <span>0{order + 1}</span>
        </RecipeContent>
      </AspectRatioWrapper>
    </Item>
  )
}
export default RecipeItem

const AspectRatioWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(2, minmax(0, 1fr));

  ${mediaQueries.min992} {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
  }
`

const StyledTriangle = styled(Triangle)`
  position: absolute;
  color: #fff;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% + 0.5px));
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  min-height: 16rem;
  background-color: #cccccc;

  ${mediaQueries.min992} {
    flex: 0 0 50%;
    min-height: 0;
    max-height: unset;
  }
`

const Item = styled.div`
  position: relative;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;

  ${mediaQueries.max576} {
    min-width: 260px;
    max-width: 16rem;
    flex: 1 0 auto;
  }

  &:first-of-type {
    ${mediaQueries.max576} {
      ${Image} {
        margin-bottom: auto;
      }
    }

    ${mediaQueries.min992} {
      grid-column: 1 / span 2;
      background-color: var(--primary);

      ${StyledTriangle} {
        color: var(--primary);

        top: 50%;
        left: 0;
        transform: rotate(-90deg) translate(-50%, calc(-50% + 0.5px));
        transform-origin: center left;
      }

      h6 {
        color: var(--secondary-light);
      }

      h5 {
        color: #f7f2e9;
      }

      p,
      span {
        color: #fff;
      }
    }
  }

  &:nth-of-type(2) {
    ${Image} {
      order: 1;

      ${mediaQueries.max576} {
        margin-top: auto;
      }
    }

    ${StyledTriangle} {
      top: unset;
      bottom: 0;
      transform: rotate(0.5turn) translate(50%, 0.5px);
      transform-origin: bottom center;
    }

    ${mediaQueries.min992} {
      grid-column: 1 / span 2;

      ${StyledTriangle} {
        top: 50%;
        right: 0;
        left: unset;
        transform: rotate(90deg) translate(50%, calc(-50% + 0.5px));
        transform-origin: center right;
      }
    }
  }

  &:last-of-type {
    ${mediaQueries.max576} {
      ${Image} {
        margin-bottom: auto;
      }
    }

    ${mediaQueries.min992} {
      grid-column: 3 / span 1;
      grid-row: 1 / span 2;
    }

    ${AspectRatioWrapper} {
      flex-direction: column;
    }

    ${StyledTriangle} {
      top: 0;
      left: 50%;
      transform: translate(-50%, calc(-100% + 0.5px));
      transform-origin: center;
    }
  }

  ${mediaQueries.min992} {
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: calc(100% / 1 / 2);
    }
  }
`

const RecipeContent = styled.div`
  position: relative;
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;

  h6 {
    margin-bottom: 0.5rem;
    color: var(--secondary);
    font-weight: 800;
    text-transform: uppercase;
    max-width: 15ch;
    letter-spacing: 1.8px;
  }

  h5 {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--primary);

    ${mediaQueries.max576} {
      padding: 0 0.25rem;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }
  }

  p {
    --lines: 4;
    --base-line-height: 1.5;
    display: block;
    height: calc(var(--lines) * var(--base-font-size) * var(--base-line-height));
    overflow: hidden;
    margin-bottom: 0.5rem;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    color: #777;

    ${mediaQueries.max576} {
      --base-font-size: 14px;
    }
  }

  span {
    ${headingsFont}
    font-size: 0.75rem;
    color: #202020;
  }

  ${mediaQueries.min992} {
    padding: 2rem;

    h6 {
      max-width: 200px;
      margin-bottom: 1.25rem;
      line-height: 1.2;
    }

    h5 {
      margin-bottom: 1.25rem;
      line-height: 1.5;
    }
  }
`
